import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class GroupLifeInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Group Life Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Group Life Insurance enables a company in Louisiana to provide life insurance to everyone within a company." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Group meeting" />
        <Callout
          title="Group Life Insurance"
          content="Group Life Insurance enables a company in Louisiana to provide life insurance to everyone within a company. It’s often included as part of a benefits package with options for an employee to choose additional coverage limits. Group policies are especially beneficial because they often cost far less than what someone would pay individually. Group life remains in force until an employee is terminated or when the coverage ends."
        />
        <div className="site-body page-content">
          <h2>Group Life Insurance Payouts are Usually Used to Cover</h2>
          <p>
          <ul>
          <li><strong>End-of-life expenses.</strong> This pays for funeral expenses.</li>
          <li><strong>Day-to-day bills.</strong> Car payments, car insurance, groceries, clothes and school supplies for the kids.</li>
          <li><strong>The Mortgage.</strong> Knowing that life insurance proceeds can be put toward paying the mortgage can offer your spouse the freedom to make decisions that are best for your family during a trying time.</li>
          <li><strong>Cosigned debts.</strong> Even if you’re unmarried, there may be a co-signer who would be responsible for the outstanding debt you left behind.</li>
          </ul>
          </p>
          <DidYouKnow
            fact="68% of workers would be somewhat or very likely to purchase voluntary group life insurance if it was offered by their employer."
            source="OneAmerica by Harris Poll"
          />
          <h2>What Group Life Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>While some life insurance policies have a suicide clause that allows for payment of benefits after the policy is over two years old, not all policies have this clause.</li>
          <li>Certain reckless endangerments are excluded in many policies.</li>
          </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Group Life Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default GroupLifeInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "group-life-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-25.jpg" }) {
      ...mainBg
    }
  }
`;
